// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-model-kits-tsx": () => import("./../../../src/pages/model-kits.tsx" /* webpackChunkName: "component---src-pages-model-kits-tsx" */),
  "component---src-templates-kit-tsx": () => import("./../../../src/templates/Kit.tsx" /* webpackChunkName: "component---src-templates-kit-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/Posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

